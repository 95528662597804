<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-lg-4 mt-5">
        <h1 class="text-center">FITSEVENELEVEN</h1>
        <form @submit.prevent="login()">
          <div class="form-group">
            <label for="email">E-Mail</label>
            <input type="email" name="email" class="form-control" v-model="form.email" required />
          </div>
          <div class="form-group">
            <label for="password">Passwort</label>
            <input
              type="password"
              name="password"
              class="form-control"
              v-model="form.password"
              required
            />
          </div>
          <button type="submit" class="btn btn-dark btn-block" :disabled="submitting">Anmelden</button>
          <div class="alert alert-danger mt-3" v-if="errMsg">{{errMsg}}</div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '../firebase';

export default {
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      errMsg: '',
      submitting: false
    };
  },
  methods: {
    async login() {
      this.submitting = true;

      try {
        await auth.signInWithEmailAndPassword(
          this.form.email,
          this.form.password
        );

        this.$router.push({ name: 'dashboard' });
      } catch (err) {
        this.errMsg = err.message;

        throw err;
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>
